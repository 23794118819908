#video{
    width: 100%;
    height: 100%;
    position: fixed;
    object-fit: cover;
    z-index: -1;
}

.hero{
    height: 100vh;
    width: 100%;
    object-fit: contain;
}

.hero .content{
    text-align: center;
    height: 100%;
    width: 100%;
    top: 40vh;
    position: absolute;
    margin: auto;
}

.hero .content h1{
    font-size: 4rem;
    color: #fff;
}

.hero p {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 200;
    margin-bottom: 1.6rem;
    color: #fff;
}

.hero .btn{
    margin: 1rem 0.2rem;
}

@media screen and (max-width: 640px) {
    .content h1{
        font-size: 3rem;
    }

    .content p {
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
    }
}