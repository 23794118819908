.footer{
    width: 100%;
    padding: 6rem 0;
    background: rgba(0, 0, 0, .8);
}

.footer-container{
    max-width: 1140px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 40px;
    margin: auto;
}

.left{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: 100%;
    height: 100%;
}

.footer .right{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: 100%;
    height: 100%;
    margin: auto;
}

.footer h4{
    font-size: 1rem;
    padding: 1rem 0;
}

.footer p{
    font-size: 1rem;
    padding: 1rem 0;
}

.footer .location{
    display: flex;
    align-items: center;
}

.footer .location p {
    padding-bottom: .5rem;
}

.footer .location h4 {
    padding-top: 0;
}

.footer .phone{
    display: flex;
    align-items: center;
}

.footer .email{
    display: flex;
    align-items: center;
}

.footer .social{
    align-items: center;
    justify-content: center;
}

.right{
    justify-content: center;
}

@media screen and (max-width: 640px) {
    .footer-container{
        grid-template-columns: 1fr;
    }

    .right .social{
        margin: auto;
    }
}


