@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;300;400;500;600;700;800&display=swap');


*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    background: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Outfit', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

h1,p,a, h4, label{
    color: white;
}

ul{
    list-style-type: none;
}

a{
    text-decoration: none;
}

.btn{
    background: transparent;
    border: 1px solid white;
    font-size: 1.2rem;
    text-transform: uppercase;
    padding: 12px 32px;
    color: white;
    cursor: pointer;
}

.btn-light{
    background: rgba(255, 255, 255, .2);
}

.btn:hover{
    background: rgba(255, 255, 255, .2);
}