.pricing{
    width: 100%;
    padding: 6rem 1rem;
    background-color: #000;
}

.card-container{
    max-width: 1140px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;
    margin: auto;
}

.card-container .card{
    border: 1px solid #eee;
    color: white;
}

.card{
    text-align: center;
    padding: 1rem;
}

.card :hover{
    background-color: #141414;
}

.card h3{
    font-size: 1.4rem;
    padding: 1rem;
}

.card .bar{
    border-bottom: 1ps solid #eee;
    width: 10%;
    margin: 1.5rem auto;
    display: block;
}

.card .btc{
    font-weight: 600;
    font-size: 4rem;
    padding: 1rem;
}

.pricing p{
    font-size: 1.2rem;
    color: #eee;
    padding: 1rem 0;
}

.card .btn{
    width: 80%;
    margin: 2rem auto;
    display: block;
}

@media screen and (max-width: 980px) {
    .card-container{
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }
}




