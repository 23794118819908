.form{
    margin-top: 10rem;
}

form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: auto;
    max-width: 600px;
}

form label{
    margin-bottom: .5rem;
}

form input, textarea{
    margin-bottom: 1rem;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-family: 'Outfit' sans-serif;
    background: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
    color: #f4f4f4;
}

@media screen and (max-width: 1140px) {
    .form{
        margin-top: 1rem;
    }
}